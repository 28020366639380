
import request from '@/utils/request';

/**
 * 单个设置职级启用禁用
 * @param {rankId,isDisable} data
 * @returns
 */
export function setIsDisable(data) {
  return request({
    url: "user-web-api/admin/rank/updateDisable",
    method: 'POST',
    data,
  })
}

/**
 * 多个启用禁用
 * @param data rankIds,isDisable
 * @returns {AxiosPromise}
 */
export  function  multipleIsDisable(data){
  return request({
    url: "user-web-api/admin/rank/updateDisable",
    method: 'POST',
    data,
  })
}

/**
 * 新增职级
 * @param data {rankName}
 * @returns {AxiosPromise}
 */
export  function  addGrade(data){
  return request({
    url: "user-web-api/admin/rank/add",
    method: 'POST',
    data,
  })
}

export  function  updateGrade(data){
  return request({
    url: "user-web-api/admin/rank/updateInfo",
    method: 'POST',
    data,
  })
}

/**
 * 无分页职级列表
 * @returns {AxiosPromise}
 * @constructor
 */
export function rankListNoPage(data){
  return request({
    url: "user-web-api/admin/rank/getList",
    method: 'POST',
    data,
  })
}


