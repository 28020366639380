<template>
  <el-dialog :title="title" :visible="visible" width="30%" v-loading="loading" :before-close="handleCancel">
    <el-form :model="form" :rules="rules" ref="modalFrom" label-width="100px" size="small">
      <el-form-item prop="departmentId" v-show="false">
        <el-input disable v-model="form.departmentId"></el-input>
      </el-form-item>
      <el-form-item label="部门名称：" prop="departmentName">
        <el-input v-model="form.departmentName" />
      </el-form-item>
      <el-form-item label="上级部门：" prop="parentId" v-if="parseInt(form.parentId) !== -1">
        <el-select-tree
          check-strictly
          style="width: 100%"
          v-model="form.parentId"
          :data="departmentTree"
          :props="{ label: 'departmentName', child: 'children', value: 'departmentId' }"
        >
        </el-select-tree>
      </el-form-item>
      <el-form-item label="部门负责人：" prop="headerPeople">
        <el-input v-model="form.headerPeople" disabled>
          <template slot="append">
            <el-button type="primary" @click="handleSelectUser">选择</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="创建日期：" prop="createTime">
        <el-date-picker
          style="width: 100%"
          v-model="form.createTime"
          type="datetime"
          placeholder="选择日期时间"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleOk">确 定</el-button>
    </span>
    <select-user-modal ref="SelectUserModal" @selectUser="selectUser" />
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs';
import TableModalMinix from '@/minixs/TableModalMinix';
import SelectUserModal from '@/components/select-user-modal/SelectUserModal.vue';

export default {
  name: 'EditDepartmentModal',
  components: {
    SelectUserModal
  },
  mixins: [TableModalMinix],
  props: {
    departmentTree: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      title: '',
      visible: false,
      isEdit: false,
      loading: false,
      pickerOptions: {},
      defaultForm: {
        departmentId: '',
        departmentName: '',
        parentId: '',
        createTime: '',
        headerPeople: '',
        headerPeopleId: ''
      },
      form: {
        departmentId: '',
        departmentName: '',
        parentId: '',
        createTime: '',
        headerPeople: '',
        headerPeopleId: ''
      },
      url: {
        add: 'user-web-api/admin/department/add',
        update: 'user-web-api/admin/department/edit'
      },
      rules: {
        departmentName: [
          { required: true, message: '请输入部门名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        parentId: [{ required: true, message: '请选择上级部门', trigger: 'blur' }],

        createTime: [{ required: true, message: '请选择创建日期', trigger: 'blur' }]
      }
    };
  },
  methods: {
    add(command, node) {
      this.isEdit = false;
      this.title = '新增';
      if (command === 1) {
        // 新增下级部门
        this.form.parentId = String(node.departmentId);
      }
      if (command === 2) {
        // 新增同级
        this.form.parentId = String(node.parentId);
      }
      this.visible = true;
    },
    edit(node) {
      this.isEdit = true;
      this.title = '编辑';
      this.form.departmentId = node.departmentId;
      this.form.departmentName = node.departmentName;
      this.form.parentId = String(node.parentId);
      this.form.createTime = node.createTime;
      this.form.headerPeople = node.userName;
      this.form.headerPeopleId = node.userId;
      this.visible = true;
    },
    handleClose() {
      console.log('close');
      // this.form = this.defaultForm
    },

    handleSelectParent(node, data) {
      console.log(node, 'node');
      console.log(data, 'data');
    },
    async handleOk() {
      const that = this;
      if (this.confirmLoading) {
        return;
      }
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            const updateParams = {
              data: {
                departmentId: this.form.departmentId,
                parentId: this.form.parentId,
                createTime: dayjs(this.form.createTime).format('YYYY-MM-DD HH:mm:ss'),
                headerPeople: this.form.headerPeople,
                departmentName: this.form.departmentName,
                headerPeopleId: this.form.headerPeopleId
              }
            };
            await this.handleModalUpdate(updateParams, '操作成功');
          } else {
            const params = {
              data: {
                parentId: this.form.parentId,
                createTime: dayjs(this.form.createTime).format('YYYY-MM-DD HH:mm:ss'),
                headerPeople: this.form.headerPeople,
                departmentName: this.form.departmentName,
                headerPeopleId: this.form.headerPeopleId
              }
            };
            await this.handleModalAdd(params, '操作成功');
          }
          this.$emit('modalFormOk');
          this.handleCancel();
        }
      });
      console.log('ok');
    },

    handleSelectUser() {
      this.$refs.SelectUserModal.visible = true;
    },
    selectUser(user) {
      console.log(user, 'selectUser');
      this.form.headerPeople = user.userName;
      this.form.headerPeopleId = user.userId;
    }
  }
};
</script>

<style scoped></style>
