<template>
  <section class="department-info">
    <common-title title="部门基本信息" />
    <article class="department-info-content">
      <el-row :gutter="24">
        <el-col :span="6">
          <div class="department-info-item">
            <div class="department-info-item-name">部门名称：</div>
            <div class="department-info-item-text">
              <span style="color: #2861e2">{{ depInfo.departmentName }}</span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="department-info-item">
            <div class="department-info-item-name">部门ID：</div>
            <div class="department-info-item-text">
              {{ depInfo.departmentId }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="department-info-item">
            <div class="department-info-item-name">上级部门名称：</div>
            <div class="department-info-item-text">
              {{ depInfo.parentName }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="department-info-item">
            <div class="department-info-item-name">上级部门ID：</div>
            <div class="department-info-item-text">
              {{ depInfo.parentId !== -1 ? depInfo.parentId : '' }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="department-info-item">
            <div class="department-info-item-name">部门负责人：</div>
            <div class="department-info-item-text">
              {{ depInfo.headerPeopleName }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="department-info-item">
            <div class="department-info-item-name">部门在职人数：</div>
            <div class="department-info-item-text">
              {{ depInfo.count }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="department-info-item">
            <div class="department-info-item-name">创建日期：</div>
            <div class="department-info-item-text">
              {{ depInfo.createTime }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="department-info-item">
            <div class="department-info-item-name">最近更新时间：</div>
            <div class="department-info-item-text">
              {{ depInfo.updateTime }}
            </div>
          </div>
        </el-col>
      </el-row>
    </article>
  </section>
</template>

<script>
import CommonTitle from '@/components/common-title/index.vue';
import { getDepartmentDetail } from '@/api/department';

export default {
  name: 'DepartmentInfo',
  components: {
    CommonTitle
  },
  props: {
    departmentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      depInfo: {}
    };
  },
  watch: {
    departmentId: {
      handler: function () {
        this.getDetail();
      },
      immediate: true
    }
  },
  methods: {
    async getDetail() {
      if (!this.departmentId) {
        return;
      }
      try {
        const params = {
          data: {
            departmentId: this.departmentId
          }
        };
        const res = await getDepartmentDetail(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.depInfo = res;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped lang="less">
.department-info {
  background: #fff;
  .department-info-content {
    padding: 15px 20px 0 20px;
  }

  .department-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;

    .department-info-item-name {
    }

    .department-info-item-text {
      font-size: 14px;
    }
  }
}
</style>
