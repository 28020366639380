<template>
  <el-drawer
    :title="title"
    :visible="visible"
    direction="rtl"
    custom-class="draw-wrapper"
    ref="drawer"
    size="480px"
    :wrapperClosable="false"
    :before-close="handleCancel"
  >
    <div class="draw-content">
      <el-form
        :model="form"
        :rules="rules"
        ref="modalFrom"
        label-width="140px"
        size="small"
        class="draw-form"
      >
        <el-form-item prop="platformUserId" v-show="false">
          <el-input disable v-model="form.platformUserId"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名：" prop="userName">
          <el-input v-model="form.userName" />
        </el-form-item>
        <el-form-item label="手机号码：" prop="phoneNumber">
          <el-input v-model="form.phoneNumber" />
        </el-form-item>
        <el-form-item label="ERP员工编号：" prop="erpUserId">
          <el-autocomplete
            v-model="erpUser"
            value-key="empno"
            label="empname"
            :fetch-suggestions="querySearchStaffAsync"
            placeholder="请输入ERP员工名称"
            style="width: 100%"
            popper-class="my-autocomplete"
          >
            <template slot-scope="{ item }">
              <div @click="onErpUserChange(item)">
                <div class="prdtname">
                  <span>{{ item.empname + ' - ' }}</span>
                  <span>{{ item.empno }}</span>
                </div>
                <div>
                  <!-- <span class="brdtype">{{ item.brandno + ' - ' }}</span> -->
                  <span class="brdtype">{{ item.postname }}</span>
                </div>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="企业微信二维码：" prop="enterpriseWechatImg">
          <el-upload
            class="avatar-uploader"
            :action="actionUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.enterpriseWechatImg" :src="form.enterpriseWechatImg" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>
        <el-form-item
          label="部门岗位："
          :rules="{
            required: true,
            message: '',
            trigger: 'blur'
          }"
        >
          <div
            class="department-form-item"
            v-for="(depAndPost, index) in form.userDepartmentList"
            :key="`depAndKey${index}`"
          >
            <el-form-item
              :prop="'userDepartmentList.' + index + '.departmentId'"
              :key="`depId${index}`"
              :rules="{
                required: true,
                message: '部门不能为空',
                trigger: 'blur'
              }"
            >
              <el-select-tree
                placeholder="部门"
                filterable
                :data="depList"
                :props="{ label: 'departmentName', child: 'children', value: 'departmentId' }"
                check-strictly
                v-model="depAndPost.departmentId"
                style="width: 120px; margin-right: 20px"
              />
            </el-form-item>
            <el-form-item
              :prop="'userDepartmentList.' + index + '.departmentId'"
              :key="`postId${index}`"
              :rules="{
                required: true,
                message: '岗位不能为空',
                trigger: 'blur'
              }"
            >
              <el-select
                placeholder="岗位"
                filterable
                v-model="depAndPost.postId"
                style="width: 120px; margin-right: 20px"
              >
                <el-option
                  :value="post.postId"
                  :label="post.postName"
                  v-for="post in postList"
                  :key="post.postId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" v-if="index === 0" @click="handleAddDepAndPost"
                >添加</el-button
              >
              <el-button type="primary" v-if="index !== 0" @click="handleRemoveDepAndPost(index)"
                >删除</el-button
              >
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="直属上级：">
          <el-autocomplete
            id="leader"
            v-model="form.parentUserName"
            value-key="userName"
            label="userId"
            :fetch-suggestions="querySearchUserAsync"
            placeholder="请输入员工姓名或手机号"
            style="width: 100%"
            popper-class="my-autocomplete"
            @select="selectLeader"
            class="leader"
          >
            <template slot-scope="{ item }">
              <div class="prdtname">
                <span>{{ item.userName + ' - ' }}</span>
                <span>{{ item.platformUserId }}</span>
              </div>
              <div>
                <!-- <span class="brdtype">{{ item.brandno + ' - ' }}</span> -->
                <span class="brdtype">{{ item.postNames }}</span>
              </div>
            </template>
          </el-autocomplete>
          <span v-if="isLeader" style="color: red">该员工为直属上级</span>
        </el-form-item>
        <el-form-item label="职级：" prop="rankId">
          <el-select filterable value v-model="form.rankId" style="width: 100%">
            <el-option
              :value="rank.rankId"
              :label="rank.rankName"
              v-for="rank in ranList"
              :key="rank.rankId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入职日期：" prop="entryDate">
          <el-date-picker
            v-model="form.entryDate"
            type="date"
            style="width: 100%"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="在职状态：" prop="isResign">
          <el-select v-model="form.isResign" style="width: 100%">
            <el-option :value="0" label="在职"></el-option>
            <el-option :value="1" label="离职"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click.native="handleSubmit" :loading="confirmLoading">{{
          confirmLoading ? '提交中 ...' : '确 定'
        }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import dayjs from 'dayjs';
import { getDepartments } from '@/api/department';
import { rankListNoPage } from '@/api/rank';
import { postAction } from '@/api/manage';
import TableModalMinix from '@/minixs/TableModalMinix';
import { searchemp, userList } from '@/api/user';

export default {
  name: 'EditUser',
  mixins: [TableModalMinix],
  data() {
    return {
      title: '',
      visible: false,
      isEdit: false,
      actionUrl: `${process.env.VUE_APP_BASE_URL}misc-web-api/common/file/uploadImage`,
      uploadLoading: false,
      url: {
        add: 'user-web-api/admin/user/info/platform/add',
        update: 'user-web-api/admin/user/info/platform/updateById'
      },
      ranList: [],
      depList: [], // 部门Tree
      postList: [],
      defaultForm: {
        platformUserId: '',
        userId: '', // 用户ID
        userName: '',
        jobNumber: '', // 工号
        phoneNumber: '', // 手机号
        rankId: '', // 职级
        // enterpriseWechat: '', // 企业微信
        isResign: 1,
        enterpriseWechatImg: '',
        email: '', // 邮箱
        entryDate: '', // 入职日期
        erpUserId: '',
        userDepartmentList: [
          {
            departmentId: '',
            postId: ''
          }
        ],
        parentUserId: '',
        parentUserName: ''
      },
      form: {
        platformUserId: '',
        userId: '', // 用户ID
        userName: '',
        jobNumber: '', // 工号
        phoneNumber: '', // 手机号
        rankId: '', // 职级
        // enterpriseWechat: '', // 企业微信
        enterpriseWechatImg: '', // 企业微信二维码
        isResign: 1,
        email: '', // 邮箱
        erpUserId: '',
        entryDate: '', // 入职日期
        userDepartmentList: [
          {
            departmentId: '',
            postId: ''
          }
        ],
        parentUserId: '',
        parentUserName: ''
      },

      rules: {
        userName: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: '请收入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号码不正确', trigger: 'blur' }
        ],
        rankId: [{ required: true, message: '请选择职级', trigger: 'blur' }],
        // enterpriseWechat: [{ required: true, message: '请输入企业微信号', trigger: 'blur' }],
        entryDate: [{ required: true, message: '请选择入职日期', trigger: 'change' }],
        isResign: [{ required: true, message: '请选择在职状态', trigger: 'blur' }]
      },
      isLeader: false,
      erpUser: ''
    };
  },
  created() {
    this.getRankList();
    this.getPostList();
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getDepList();
      }
    },
    isLeader(newVal) {
      const leaderSelect = document.querySelector('.leader .el-input input');
      if (newVal) {
        leaderSelect.style.border = '1px solid red';
      } else {
        leaderSelect.style.border = '1px solid #DCDFE6';
      }
    }
  },
  methods: {
    onErpUserChange(e) {
      console.log(e);
      this.form.erpUserId = e.empno;
    },
    querySearchStaffAsync(queryString, cb) {
      console.log(queryString);
      this.form.erpUserId = '';
      searchemp({
        data: {
          // dptno: this.dptno,
          keyname: queryString
        }
      }).then((res) => {
        console.log(res);
        cb(res);
      });
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传企业微信二维码大小不能超过 10MB!');
      }
      return isLt2M;
    },
    handleProgress(event, file, fileList) {
      this.uploadLoading = true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = window.URL.createObjectURL(file.raw);
      if (res.success === false) {
        this.$message.error(res.description);
        return;
      }
      this.uploadLoading = false;
      this.form.enterpriseWechatImg = res.data.http;
    },
    add() {
      this.visible = true;
      this.erpUser = '';
    },
    edit(row) {
      let departmentIds = [];
      let postIds = [];
      const {
        userName,
        // enterpriseWechat,
        entryDate,
        isResign,
        platformUserId,
        rankId,
        phoneNumber,
        email,
        enterpriseWechatImg,
        erpUserId,
        parentUserId,
        parentUserName
      } = row;
      this.form.platformUserId = platformUserId;
      this.form.userName = userName;
      // this.form.enterpriseWechat = enterpriseWechat;
      this.form.rankId = rankId;
      this.form.phoneNumber = phoneNumber;
      this.form.entryDate = entryDate;
      this.form.email = email;
      this.form.isResign = isResign;
      this.form.enterpriseWechatImg = enterpriseWechatImg;
      this.form.erpUserId = erpUserId;
      this.form.parentUserName = parentUserName;
      this.form.parentUserId = parentUserId || '';
      this.erpUser = erpUserId;
      if (typeof row.department === 'string') {
        departmentIds = JSON.parse(row.department);
      }
      if (typeof row.post === 'string') {
        postIds = JSON.parse(row.post);
      }
      const userDepartmentList = [];
      departmentIds.forEach((dep, index) => {
        const item = {
          departmentId: dep,
          postId: postIds[index]
        };
        userDepartmentList.push(item);
      });
      this.form.userDepartmentList = userDepartmentList;

      this.visible = true;
    },
    handleOk() {},
    /* 获取部门列表 */
    async getDepList() {
      try {
        const res = await getDepartments({ data: { departmentName: '', isEnable: '' } });
        if (res.success === false) {
          return;
        }
        this.depList = res;
      } catch (e) {}
    },

    // 获取职级列表
    async getRankList() {
      try {
        const res = await rankListNoPage({ data: {} });
        if (res.success === false) {
          this.$message.error(res.description);
        }
        this.ranList = res;
      } catch (e) {
        console.log(e);
      }
    },
    handleAddDepAndPost() {
      this.form.userDepartmentList.push({
        departmentIdL: '',
        postId: ''
      });
    },

    // 获取岗位列表
    async getPostList() {
      try {
        const params = { data: { pageNumber: 1, pageSize: 1000, data: {} } };
        postAction('user-web-api/admin/post/getPostList', params).then((res) => {
          if (res.success === false) {
            this.$message.error(res.description);
            return;
          }
          console.log(res);
          this.postList = res;
        });
      } catch (e) {}
    },
    handleRemoveDepAndPost(i) {
      this.form.userDepartmentList = this.form.userDepartmentList.filter((_, index) => index !== i);
    },
    // 提交
    async handleSubmit() {
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          console.log(this.form);
          if (this.erpUser && !this.form.erpUserId)
            return this.$message.error('请从搜索出来的ERP员工中选择一位~');
          if (this.isEdit) {
            const params = {
              data: {
                platformUserId: this.form.platformUserId,
                userName: this.form.userName,
                phoneNumber: this.form.phoneNumber,
                rankId: this.form.rankId,
                erpUserId: this.form.erpUserId,
                // enterpriseWechat: this.form.enterpriseWechat,
                userDepartmentList: this.form.userDepartmentList,
                email: this.form.email,
                entryDate: dayjs(this.form.entryDate).format('YYYY-MM-DD'),
                isResign: this.form.isResign,
                enterpriseWechatImg: this.form.enterpriseWechatImg,
                parentUserId: this.form.parentUserName ? this.form.parentUserId : '0'
              }
            };
            await this.handleModalUpdate(params);
          } else {
            const params = {
              data: {
                userName: this.form.userName,
                phoneNumber: this.form.phoneNumber,
                rankId: this.form.rankId,
                erpUserId: this.form.erpUserId,
                // enterpriseWechat: this.form.enterpriseWechat,
                userDepartmentList: this.form.userDepartmentList,
                email: this.form.email,
                entryDate: dayjs(this.form.entryDate).format('YYYY-MM-DD'),
                isResign: this.form.isResign,
                enterpriseWechatImg: this.form.enterpriseWechatImg,
                parentUserId: this.form.parentUserName ? this.form.parentUserId : '0'
              }
            };
            await this.handleModalAdd(params);
          }
        }
      });
    },
    // 验证部门
    checkDepAndPost(rule, value, callback) {},
    querySearchUserAsync(queryString, cb) {
      userList({
        data: {
          userNameOrPhoneNumber: queryString
        },
        pageSize: 1000,
        pageNumber: 1
      }).then((res) => {
        cb(res.content);
      });
    },
    selectLeader(e) {
      console.log(this.form);
      this.form.parentUserId = e.userId;
    }
  }
};
</script>

<style scoped lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.draw-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .draw-form {
    flex: 1;
    overflow: auto;
    padding-right: 20px;
    /deep/ .el-form-item--small.el-form-item {
      margin-bottom: 15px;
    }
    .department-form-item {
      align-items: center;

      .btn {
        flex-shrink: 0;
      }
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
}
</style>
<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .prdtname {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .brdtype {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .brdtype {
      color: #ddd;
    }
  }
}
</style>
