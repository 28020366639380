export function deepClone(obj, visited = new WeakMap()) {
  // 如果是基本类型或者 null，则直接返回
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // 如果已经访问过该对象，直接返回之前的克隆结果，防止循环引用
  if (visited.has(obj)) {
    return visited.get(obj);
  }

  // 处理数组
  if (Array.isArray(obj)) {
    const cloneArr = [];
    visited.set(obj, cloneArr);
    for (let i = 0; i < obj.length; i++) {
      cloneArr[i] = deepClone(obj[i], visited);
    }
    return cloneArr;
  }

  // 处理对象
  const cloneObj = {};
  visited.set(obj, cloneObj);
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      cloneObj[key] = deepClone(obj[key], visited);
    }
  }

  return cloneObj;
}
