<template>
  <el-dialog
    title="用户选择"
    :visible="visible"
    width="1100px"
    size="small"
    append-to-body
    @close="close"
  >
    <el-form class="search" :model="form" inline size="small">
      <el-form-item label="部门：">
        <el-select-tree
          :data="depList"
          :props="{ label: 'departmentName', child: 'children', value: 'departmentId' }"
          check-strictly
          v-model="form.departmentId"
          style="width: 120px; margin-right: 20px"
        />
      </el-form-item>
      <el-form-item label="岗位：">
        <el-select
          style="min-width: 80px"
          placeholder="岗位"
          v-model="form.post"
          multiple
          filterable
          collapse-tags
        >
          <el-option
            :value="postItem.postId"
            :label="postItem.postName"
            v-for="postItem in postList"
            :key="postItem.postId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="员工名称：">
        <el-input v-model="form.userName" size="small" placeholder="请输入姓名"> </el-input>
      </el-form-item>
      <el-form-item label="手机号码：">
        <el-input v-model="form.phoneNumber" size="small" placeholder="请输入手机号"> </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table-wrapper">
      <el-table
        ref="UserSelectTable"
        row-key="userId"
        :data="dataSource"
        style="width: 100%"
        border
        size="small"
        @current-change="handleCurrentChange"
        v-loading="loading"
      >
        <el-table-column width="55" align="center">
          <template slot-scope="{ row }">
            <el-radio :value="checkedId" :label="row.platformUserId"><span></span></el-radio>
            <!--            <el-radio value="1" label="0"><span></span></el-radio>-->
          </template>
        </el-table-column>
        <el-table-column prop="userId" label="员工ID" width="120"> </el-table-column>
        <el-table-column prop="userName" label="员工名称" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="phoneNumber" label="手机号码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="departmentNames" label="部门" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="postNames" label="岗位" show-overflow-tooltip> </el-table-column>
      </el-table>
      <div class="table-footer">
        <i-pagination
          :page-size="iPagination.pageSize"
          :current-page="iPagination.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          layout="total,prev, pager, next ,sizes,jumper"
          :total="iPagination.total"
          @current-change="handlePageChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import IPagination from '@/components/i-pagination/index.vue';
import { userList } from '@/api/user';
import { getDepartments } from '@/api/department';
import { postAction } from '@/api/manage';

export default {
  name: 'SelectUserModal',
  components: {
    IPagination
  },
  data() {
    return {
      form: {
        departmentId: '',
        post: [],
        phoneNumber: '',
        userName: ''
      },
      visible: false,
      loading: false,
      depList: [],
      dataSource: [],
      postList: [],
      iPagination: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      url: {
        list: 'user-web-api/admin/user/info/platform/pageLis'
      },
      tableColumn: [
        { type: 'radio', width: 50, align: 'center', slots: { default: 'radio' } },
        { field: 'userId', title: '员工ID' },
        { field: 'userName', title: '员工名称' },
        { field: 'departmentNames', title: '部门名称' },
        { field: 'postNames', title: '岗位' }
      ],
      selectRow: null,
      checkedId: -1
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getList();
        this.getDepList();
        this.getPostList();
      }
    }
  },
  methods: {
    close() {
      this.form.departmentId = '';
      this.form.phoneNumber = '';
      this.form.userName = '';
      this.form.post = [];
      this.dataSource = [];
      this.depList = [];
      this.postList = [];
      this.selectRow = null;
      this.checkedId = -1;
      this.visible = false;
    },
    search() {
      this.getList();
    },
    reset() {
      this.form.departmentId = '';
      this.form.phoneNumber = '';
      this.form.userName = '';
      this.form.post = [];
      this.iPagination.currentPage = 1;
      this.iPagination.pageSize = 10;
      this.getList();
    },
    async getPostList() {
      try {
        const params = { data: { pageNumber: 1, pageSize: 1000, data: {} } };
        postAction('user-web-api/admin/post/getPostList', params).then((res) => {
          console.log(res, 'psosksjdalkjdalkjdklajdajl');
          if (res.success === false) {
            this.$message.error(res.description);
            return;
          }
          console.log(res);
          this.postList = res;
        });
      } catch (e) {}
    },
    /* 获取部门列表 */
    async getDepList() {
      try {
        const res = await getDepartments({ data: { departmentName: '', isEnable: '' } });
        if (res.success === false) {
          return;
        }
        this.depList = res;
      } catch (e) {}
    },
    async getList() {
      this.loading = true;
      try {
        const formData = {
          department: this.form.departmentId ? [this.form.departmentId] : [],
          post: this.form.post.length > 0 ? this.form.post : [],
          userName: this.form.userName,
          phoneNumber: this.form.phoneNumber
        };
        const params = {
          data: { ...formData },
          pageNumber: this.iPagination.currentPage,
          pageSize: this.iPagination.pageSize
        };
        const res = await userList(params);
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.dataSource = res.content;
        this.iPagination.total = parseInt(res.total, 10);
        console.log(res);
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    handleOk() {
      this.$emit('selectUser', this.selectRow);
      this.selectRow = null;
      this.visible = false;
    },
    handlePageChange(current) {
      this.iPagination.currentPage = current;
      this.getList();
    },
    // 每页显示的条数发生的变化
    handleSizeChange(pageSize) {
      this.iPagination.pageSize = pageSize;
      this.getList();
    },
    radioChangeEvent({ row }) {
      this.selectRow = row;
    },
    handleCurrentChange(row) {
      this.checkedId = row.platformUserId;
      this.selectRow = row;
    }
  }
};
</script>

<style lang="less" scoped>
.table-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
