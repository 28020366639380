import request from '@/utils/request';

/**
 * 获取用户列表
 * @param data
 * @returns {AxiosPromise}
 */
export function userList(data) {
  return request({
    url: '/user-web-api/admin/user/info/platform/pageList',
    method: 'POST',
    data
  });
}

/**
 * 批量重置密码
 * @param {data:[]} data
 * @returns
 */
export function resetUserPassword(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/resetPwd',
    method: 'POST',
    data
  });
}

/**
 * 批量更新用户 在职，离职状态
 * @param {userIds:[],isResign}} data
 * @returns
 */
export function changeUsersIsResign(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/updateResign',
    method: 'POST',
    data
  });
}

/**
 *  启用停用
 * @param {userIds:[],isResign}} data
 * @returns
 */
export function changeUsersIsDisable(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/updateDisable',
    method: 'POST',
    data
  });
}

// 开通 启用停用坐席
export function changeSeateStatus(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/updateOpenSeats',
    method: 'POST',
    data
  });
}

/** 查询ERP员工 */
export function searchemp(data) {
  return request({
    url: 'product-web-api/api/erp/searchemp',
    method: 'POST',
    data
  });
}

// 获取呼叫平台
export function getCallApp(data) {
  return request({
    url: 'user-web-api/admin/callcenter/getCallApp',
    method: 'POST',
    data
  });
}

// 获取呼叫信息的用户信息
export function getCcUser(data) {
  return request({
    url: 'user-web-api/admin/callcenter/getCcUser',
    method: 'POST',
    data
  });
}

// 根据条件查询列表
export function getListByQuery(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/skill/groups/getListByQuery',
    method: 'POST',
    data
  });
}

/**
 *  坐席状态处理（开启或者关闭）
 * @param data {{data: {isOpenSeats, userId}}}}
 */
export function changeUserOpenSeats(data) {
  return request({
    url: 'user-web-api/admin/user/info/platform/updateOpenSeats',
    method: 'POST',
    data
  });
}

// 更新运营端用户（新增修改坐席）
export function saveCcUser(data) {
  return request({
    url: 'user-web-api/admin/callcenter/saveCcUser',
    method: 'POST',
    data
  });
}

// 获取坐席列表（用户）
export function getCcUserListByUserId(data) {
  return request({
    url: 'user-web-api/admin/callcenter/getCcUserListByUserId',
    method: 'POST',
    data
  });
}

// 删除坐席（用户）
export function delByCcUser(data) {
  return request({
    url: 'user-web-api/admin/callcenter/delByCcUser',
    method: 'POST',
    data
  });
}

export default {};
